import { useStaticQuery, graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

const query = graphql`
  query FallbackImageQuery {
    file(name: { eq: "fallback-image" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 100
          height: 680
        )
      }
    }
  }
`

const useFallbackImage = () => {
  const data = useStaticQuery(query)

  return data.file.childImageSharp.gatsbyImageData as IGatsbyImageData
}

export default useFallbackImage
